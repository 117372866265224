import React, { useContext } from 'react';

import './Stepper.scss';
import { StepContext } from '../../context/StepContext';

const getStepStyle = (position, i, steps) => {
  let className = 'step';
  if (position > i) className += ' reached';
  if (i < steps.length - 1) className += ' not-last';
  return className;
};

const Stepper = ({ steps }) => {
  const { position } = useContext(StepContext);

  return (
    <ol className="stepper">
      {steps.map((step, i) => (
        <li key={i} className={getStepStyle(position, i, steps)}>
          <div className={`badge ${position > i ? 'blue' : 'grey'}`}>{step.badgeText}</div>
          <div className="label">{step.label}</div>
        </li>
      ))}
    </ol>
  );
};

export default Stepper;

import React, { useContext } from 'react';

import { StepContext } from '../../context/StepContext';
import { ExampleContext } from '../../context/ExampleContext';

import stepOneDo from '../../assets/images/step_1_do.jpg';
import stepOneDontOne from '../../assets/images/step_1_dont_1.jpg';
import stepOneDontTwo from '../../assets/images/step_1_dont_2.jpg';
import stepOneDontThree from '../../assets/images/step_1_dont_3.jpg';
import stepTwoDo from '../../assets/images/step_2_do.jpg';
import stepTwoDontOne from '../../assets/images/step_2_dont_1.jpg';
import stepTwoDontTwo from '../../assets/images/step_2_dont_2.jpg';
import stepThreeDo from '../../assets/images/step_3_do.jpg';
import stepThreeDontOne from '../../assets/images/step_3_dont_1.jpg';
import stepThreeDontTwo from '../../assets/images/step_3_dont_2.jpg';
import doIcon from '../../assets/icons/check_green.png';
import dontIcon from '../../assets/icons/cross_red.png';

import './PhotoExample.scss';

const PhotoExamples = () => {
    const { position } = useContext(StepContext);
    const { setShowExample } = useContext(ExampleContext);

    const exampleOneRows = [
        { title: 'Lumière de jour et de face pour éviter les ombres', do: stepOneDo, dont: stepOneDontOne },
        { title: 'Les oreilles doivent être dégagées', do: stepOneDo, dont: stepOneDontTwo },
        { title: 'Ne souriez pas', do: stepOneDo, dont: stepOneDontThree },
    ];

    const exampleTwoRows = [
        {
            title: 'Tenez la carte par le bas entre l\'index et le majeur, laissant 3 côtés visibles',
            do: stepTwoDo,
            dont: stepTwoDontOne
        },
        { title: 'Mettez la carte sur le menton mais sans couvrir la bouche', do: stepTwoDo, dont: stepTwoDontTwo },
    ];

    const exampleThreeRows = [
        { title: 'Ne souriez pas', do: stepThreeDo, dont: stepThreeDontOne },
        { title: 'Les oreilles doivent être dégagées', do: stepThreeDo, dont: stepThreeDontTwo },
    ];

    const renderExamples = () => {
        let examples = [];

        if (position === 1) examples = [...exampleOneRows];
        else if (position === 2) examples = [...exampleTwoRows];
        else if (position === 3) examples = [...exampleThreeRows];

        const rows = [];

        examples.map((example, i) => {
            rows.push(
                <div key={ i } className="row">
                    <p>{ example.title }</p>
                    <div className="images">
                        <div className="do">
                            <img src={ example.do } alt="exemple de prise de photo" className="photo"/>
                            <img src={ doIcon } alt="do" className="icon"/>
                        </div>
                        <div className="dont">
                            <img src={ example.dont } alt="exemple de prise de photo" className="photo"/>
                            <img src={ dontIcon } alt="do" className="icon"/>
                        </div>
                    </div>
                </div>
            );
        });
        return rows;
    };

    return (
        <div className="example-container">
            { renderExamples() }
            <div className="buttons">
                <button className="btn-blue" onClick={ () => setShowExample(false) }>
                    Prendre la photo
                </button>
            </div>
        </div>
    );
};

export default PhotoExamples;

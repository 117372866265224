import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';

import App from './App';
import StepContextProvider from './context/StepContext';
import OrderContextProvider from './context/OrderContext';
import ExampleContextProvider from './context/ExampleContext';

ReactDOM.render(
  <BrowserRouter>
    <OrderContextProvider>
      <StepContextProvider>
        <ExampleContextProvider>
          <App />
        </ExampleContextProvider>
      </StepContextProvider>
    </OrderContextProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();

import React from 'react';

import logo from '../../assets/images/logo_full_white.png';
import './EndScreen.scss';

const EndScreen = () => (
    <div className="end-screen-container">
        <img src={ logo } alt="logo Metaoptics"/>
        <p>La prise de photos est terminée. Notre opticien va déterminer les mesures afin de fabriquer et monter vos
            verres.</p>
        <p>Vous recevrez un e-mail dès la validation de votre commande.</p>
    </div>
);

export default EndScreen;

import { API, PHOTO_RETRIEVAL_REQUEST } from '../config';

const getStep = async (orderNumber, setLoading) => {
  const response = await fetch(`${API}/${PHOTO_RETRIEVAL_REQUEST}?orderNumber=${orderNumber}&retrieveImage=false`, {
    method: 'GET',
  });

  if (response.status !== 200) {
    setLoading(false);
    return 1;
  }

  const { photoDataList } = await response.json();
  setLoading(false);
  return photoDataList.length === 0 ? 1 : photoDataList.length + 1;
};

export default getStep;

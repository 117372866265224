import React, { useContext, useState } from 'react';

import { StepContext } from '../../context/StepContext';
import { OrderContext } from '../../context/OrderContext';
import sendPhotos from '../../services/sendPhotos';
import formatDataUri from '../../services/formatDataUri';
import Spinner from '../Spinner/Spinner';

import validated from '../../assets/icons/check_round.png';
import refused from '../../assets/icons/cross_round.png';

import './PhotoPreview.scss';

const PhotoPreview = ({ uri, setUri, setDisplayCamera }) => {
  const { position, setPosition } = useContext(StepContext);
  const { orderNumber } = useContext(OrderContext);

  const [loading, setLoading] = useState(false);

  const save = async () => {
    setLoading(true);
    const photoData = { id: position, step: `${position}`, data: formatDataUri(uri) };
    const res = await sendPhotos(orderNumber, [photoData]);
    if (res.status === 200) {
      setDisplayCamera(false);
      setPosition(position + 1);
    }
    setLoading(false);
  };

  return (
    <>
      <img className="preview" src={uri} alt="Take by user" />
      <div className="controls">
        {loading ? (
          <Spinner color="blue" className="spinner" />
        ) : (
          <>
            <img className="controls-button" src={refused} alt="Refused" onClick={() => setUri(null)} />
            <img className="controls-button" src={validated} alt="Validated" onClick={save} />
          </>
        )}
      </div>
    </>
  );
};

export default PhotoPreview;

import React, { createContext, useState, useEffect } from 'react';

export const ExampleContext = createContext([]);

const ExampleContextProvider = ({ children }) => {
  const [showExample, setShowExample] = useState(false);

  return <ExampleContext.Provider value={{ showExample, setShowExample }}>{children}</ExampleContext.Provider>;
};

export default ExampleContextProvider;

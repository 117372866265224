import React from 'react';

import logo from '../../assets/images/logo_full_white.png';
import './SplashScreen.scss';
import Spinner from '../Spinner/Spinner';

const SplashScreen = () => {
  return (
    <div className="splashscreen-container">
      <img src={logo} alt="logo Metaoptics" />
      <Spinner color="#fff" />
      <p style={{ color: '#ffff' }}>Chargement...</p>
    </div>
  );
};

export default SplashScreen;

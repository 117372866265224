import React, { useState, useContext, useEffect } from 'react';
import { isMobile } from 'react-device-detect';

import { OrderContext } from './context/OrderContext';
import { StepContext } from './context/StepContext';
import getStep from './services/getStep';
import Router from './navigation/Router';
import Error from './components/Error/Error';
import SplashScreen from './components/SplashScreen/SplashScreen';

import './assets/scss/index.scss';

const App = () => {
  const { orderNumber } = useContext(OrderContext);
  const { setPosition } = useContext(StepContext);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (orderNumber) {
        const step = await getStep(orderNumber, setLoading);
        if (step) {
          setPosition(step);
        }
      } else {
        setLoading(false);
      }
    })();
  }, [orderNumber]);

  if (!isMobile) {
    const message = 'Cette application fonctionne seulement sur smartphone et tablette.';
    return <Error message={message} />;
  }

  if (loading) return <SplashScreen />;

  return <Router />;
};

export default App;

import React, { createContext, useState } from 'react';

export const StepContext = createContext([]);

const StepContextProvider = ({ children }) => {
    const [position, setPosition] = useState(0);

    return <StepContext.Provider value={ { position, setPosition } }>{ children }</StepContext.Provider>;
};

export default StepContextProvider;

import React from 'react';

import logoHead from '../../assets/images/logo_head_white.png';
import logoText from '../../assets/images/logo_text_white.png';
import './TopBar.scss';

const TopBar = () => {
  return (
    <div className="topBar">
      <img src={logoHead} className="logoHead" alt="logo Metaoptics" />
      <img src={logoText} className="logoText" alt="logo Metaoptics" />
    </div>
  );
};

export default TopBar;

import React, { useContext, useState } from 'react';
import { withOrientationChange } from 'react-device-detect';

import { OrderContext } from '../context/OrderContext';
import { StepContext } from '../context/StepContext';
import { ExampleContext } from '../context/ExampleContext';

import OrderNumber from '../components/OrderNumber/OrderNumber';
import TopBar from '../components/TopBar/TopBar';
import MainView from '../components/MainView/MainView';
import EndScreen from '../components/EndScreen/EndScreen';
import PhotoCapture from '../components/PhotoCapture/PhotoCapture';
import PhotoExamples from '../components/PhotoExamples/PhotoExamples';
import Error from '../components/Error/Error';
import StartScreen from '../components/StartScreen/StartScreen';

const LANDSCAPE_ERROR_MSG = 'Merci d\'utilier cette application en mode portrait uniquement.';

const Router = ({ isLandscape }) => {
    const { orderNumber } = useContext(OrderContext);
    const { position } = useContext(StepContext);
    const { showExample } = useContext(ExampleContext);

    const [displayCamera, setDisplayCamera] = useState(false);

    if (showExample && position > 0 && position < 4) {
        if (isLandscape) return <Error message={ LANDSCAPE_ERROR_MSG }/>;

        return (
            <>
                <TopBar/>
                <PhotoExamples/>
            </>
        );
    }

    if (position === 0) return <StartScreen/>;

    if (position === 5) return <EndScreen/>;

    if (orderNumber !== '') {
        if (isLandscape) return <Error message={ LANDSCAPE_ERROR_MSG }/>;

        return (
            <>
                <TopBar/>
                { displayCamera ? (
                    <PhotoCapture setDisplayCamera={ setDisplayCamera }/>
                ) : (
                    <MainView setDisplayCamera={ setDisplayCamera }/>
                ) }
            </>
        );
    }

    return <OrderNumber/>;
};

export default withOrientationChange(Router);

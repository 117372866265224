import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const OrderContext = createContext([]);

const OrderContextProvider = ({ children }) => {
  const [orderNumber, setOrderNumber] = useState('');

  const url = useLocation();

  useEffect(() => {
    const order = url.search.split('=')[1];
    if (order) {
      setOrderNumber(order);
    }
  }, []);

  return <OrderContext.Provider value={{ orderNumber, setOrderNumber }}>{children}</OrderContext.Provider>;
};

export default OrderContextProvider;

import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { OrderContext } from '../../context/OrderContext';

import logo from '../../assets/images/logo_full_white.png';
import './OrderNumber.scss';

const OrderNumber = () => {
  const history = useHistory();

  const { setOrderNumber } = useContext(OrderContext);
  const [newOrderNumber, setNewOrderNumber] = useState('');

  const handleSubmit = e => {
    e.preventDefault();
    if (newOrderNumber !== '') {
      setOrderNumber(newOrderNumber);
      history.replace(`/?order=${newOrderNumber}`);
    }
  };

  return (
    <div className="container">
      <img src={logo} alt="logo Metaoptics" />
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="orderNumberInput">Indiquez votre numéro de commande</label>
          <input
            type="text"
            name="orderNumber"
            id="orderNumberInput"
            placeholder="N° de commande"
            value={newOrderNumber}
            onChange={e => setNewOrderNumber(e.target.value)}
          />
        </div>
        <button className="btn-white" disabled={newOrderNumber === ''}>
          Valider
        </button>
      </form>
    </div>
  );
};

export default OrderNumber;

import React from 'react';

import Stepper from '../Stepper/Stepper';
import PhotoSteps from '../PhotoSteps/PhotoSteps';

import './MainView.scss';

const STEPS = [
  { badgeText: '1', label: 'Étape 1' },
  { badgeText: '2', label: 'Étape 2' },
  { badgeText: '3', label: 'Étape 3' },
  { badgeText: '4', label: 'Étape 4' },
];

const MainView = ({ setDisplayCamera }) => {
  return (
    <div className="main-view-container">
      <Stepper steps={STEPS} />
      <PhotoSteps setDisplayCamera={setDisplayCamera} />
    </div>
  );
};

export default MainView;

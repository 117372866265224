import { API, PHOTO_SENDING_REQUEST } from '../config';

const sendPhotos = async (orderNumber, photoDataList) => {
  return await fetch(`${API}/${PHOTO_SENDING_REQUEST}`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ orderNumber, photoDataList }),
  });
};

export default sendPhotos;

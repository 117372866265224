import React from 'react';
import PropTypes from 'prop-types';

import './Spinner.scss';

const Spinner = ({ color, size, className }) => {
  const classes = () => ['spinner', className].join(' ');

  return (
    <svg className={classes()} viewBox="0 0 50 50" height={size} width={size}>
      <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5" stroke={color} />
    </svg>
  );
};

Spinner.propTypes = {
  color: PropTypes.string,
  size: PropTypes.number,
  className: PropTypes.array,
};

Spinner.defaultProps = {
  color: '#000000',
  size: 50,
  className: [],
};

export default Spinner;

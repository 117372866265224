import React, { useContext, useState } from 'react';
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

import { StepContext } from '../../context/StepContext';

import useWindowSize from '../../hooks/useWindowSize';
import PhotoPreview from '../PhotoPreview/PhotoPreview';
import Spinner from '../Spinner/Spinner';

import mask from '../../assets/images/gauge.png';
import frame from '../../assets/images/photo_frame_2.png';
import './PhotoCapture.scss';

const TOP_BAR_HEIGHT = 70;

const PhotoCapture = ({ setDisplayCamera }) => {
  const { position } = useContext(StepContext);

  const { height } = useWindowSize();

  const [uri, setUri] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const onCameraStart = stream => {
    if (stream) setIsLoading(false);
  };

  const onTakePhotoAnimationDone = uri => setUri(uri);

  return (
    <>
      {uri ? (
        <PhotoPreview uri={uri} setUri={setUri} setDisplayCamera={setDisplayCamera} />
      ) : (
        <div className="camera-container">
          <Camera
            onTakePhotoAnimationDone={onTakePhotoAnimationDone}
            onCameraStart={onCameraStart}
            idealFacingMode={FACING_MODES.ENVIRONMENT}
            imageType={IMAGE_TYPES.PNG}
            isImageMirror={false}
            isMaxResolution={true}
            imageCompression={0}
            sizeFactor={1}
            isFullscreen={false}
          />
          {isLoading ? (
            <Spinner className={['spinner']} size={80} color="#065be0" />
          ) : (
            <img
              className={position !== 4 ? 'face-mask' : 'frame-mask'}
              src={position !== 4 ? mask : frame}
              alt="Mask"
              style={position !== 4 ? { height: (height - TOP_BAR_HEIGHT) / 3 } : {}}
            />
          )}
        </div>
      )}
    </>
  );
};

export default PhotoCapture;

import React, { useContext } from 'react';

import { StepContext } from '../../context/StepContext';
import { ExampleContext } from '../../context/ExampleContext';

import logo from '../../assets/images/logo_head_blue.png';
import photoFrame from '../../assets/images/photo_frame_1.png';
import './PhotoSteps.scss';

const PhotoSteps = ({ setDisplayCamera }) => {
    const { position } = useContext(StepContext);
    const { setShowExample } = useContext(ExampleContext);

    const personPhotoSteps = position === 1 || position === 2 || position === 3;

    const renderTitle = () => {
        let title = 'Photo';
        if (position === 1) title += ' avec lunettes';
        else if (position === 2) title += ' sans lunettes avec carte blanche sur le menton';
        else if (position === 3) title += ' sans rien : ';
        else if (position === 4) title += ' de votre ordonnance';
        return <h1>{ title }{ position === 3 && <strong>C'est la dernière !</strong> }</h1>;
    };

    const renderText = () => {
        let text = [];

        if (position === 1) {
            text.push(
                <div key={ position }>
                    <p className="section-title">Préparation</p>
                    <p>- Photo sur <strong>fond uni clair</strong></p>
                    <p>- <strong>Lumière de jour</strong> pour éviter les ombres sur le visage</p>
                    <p>- <strong>Limitez les reflets</strong> sur les verres des lunettes</p>
                    <p className="section-title">Cadrage de la photo</p>
                    <p>- Cheveux attachés et <strong>oreilles visibles</strong></p>
                    <p>- Le sujet prend une attitude naturelle, <strong>regard au loin droit devant</strong> qui
                        traverse l'appareil photo. <strong>Ne pas sourire !</strong></p>
                    <p>- Gardez la même attitude sur les 3 photos !</p>
                    <p>- Objectif de l'<strong>appareil photo au niveau des yeux du sujet</strong></p>
                    <p>- Le visage doit remplir l'ovale de cadrage. <strong>Avancez ou reculez si besoin</strong></p>
                    <p>- Allez-y !</p>
                </div>
            );
        }

        if (position === 2) {
            text.push(
                <div key={ position }>
                    <p className="section-title">Préparation</p>
                    <p>- <strong>Même position</strong> que sur la première photo</p>
                    <p>- Carte à fond blanc <strong>posée sur la pointe du menton</strong> (dos de votre carte de sécu)
                    </p>
                    <p>- Tenez la carte de manière à <strong>laisser les 4 coins visibles</strong></p>
                    <p className="section-title">Cadrage de la photo</p>
                    <p>- Le visage doit remplir l'ovale de cadrage. <strong>Avancez ou reculez si besoin</strong></p>
                    <p>- Objectif de l'<strong>appareil photo au niveau des yeux du sujet</strong></p>
                    <p>- Allez-y !</p>
                </div>
            );
        }

        if (position === 3) {
            text.push(
                <div key={ position }>
                    <p className="section-title">Préparation</p>
                    <p>- <strong>Même position</strong> que sur les premières photos</p>
                    <p>- <strong>Regard au loin, droit devant</strong>, qui traverse l'appareil photo. <strong>Ne pas
                        sourire</strong></p>
                    <p className="section-title">Cadrage de la photo</p>
                    <p>- Le visage doit remplir l'ovale de cadrage. <strong>Avancez ou reculez si besoin</strong></p>
                    <p>- Objectif de l'<strong>appareil photo au niveau des yeux du sujet</strong></p>
                    <p>- Allez-y !</p>
                </div>
            );
        }

        return text;
    };

    return (
        <>
            { personPhotoSteps ? (
                <img src={ logo } alt="logo Metaoptics" className="logo"/>
            ) : (
                <img src={ photoFrame } alt="cadre" className="frame"/>
            ) }
            <div className="text">
                { renderTitle() }
                { personPhotoSteps && renderText() }
            </div>
            { personPhotoSteps ? (
                <div className="buttons">
                    <button className="btn-outline" onClick={ () => setShowExample(true) }>Voir un exemple</button>
                    <button className="btn-blue" onClick={ () => setDisplayCamera(true) }>
                        Prendre la photo
                    </button>
                </div>
            ) : (
                <button className="btn-blue test" onClick={ () => setDisplayCamera(true) }>
                    Prendre la photo
                </button>
            ) }
        </>
    );
};

export default PhotoSteps;

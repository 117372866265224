import React from 'react';

import logo from '../../assets/images/logo_full_white.png';
import './Error.scss';

const Error = ({ message }) => {
  return (
    <div className="error-container">
      <img src={logo} alt="logo Metaoptics" />
      <p>{message}</p>
    </div>
  );
};

export default Error;

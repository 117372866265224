import React, { useContext } from 'react';

import { StepContext } from '../../context/StepContext';

import logo from '../../assets/images/logo_full_white.png';
import './StartScreen.scss';

const StartScreen = () => {
    const { setPosition } = useContext(StepContext);

    return (
        <div className="start-screen-container">
            <img src={ logo } alt="logo Metaoptics"/>
            <p>Merci de vous assurer que votre navigateur est autorisé à accéder à votre caméra.</p>
            <button type='button' className='btn-white' onClick={ () => setPosition(1) }>Suivant</button>
        </div>
    );
};

export default StartScreen;
